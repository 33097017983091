import { graphql, Link, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"
import logo from "../images/logo-square-white.svg"
import { useContext, useRef } from "react"
import { Container, heroGradient, primaryColor } from "./styled-components"
import { rhythm } from "../utils/typography"
import SearchContext from "../contexts/search-sontext"
import * as React from "react"

const heroCss = css`
  background-image: ${heroGradient};
  background-color: ${primaryColor};
  color: #fff;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const heroBodyCss = css`
  flex-grow: 1;
  flex-shrink: 0;
  padding: ${rhythm(2)} ${rhythm(1)};
  color: #fff;
`

const titleCss = css`
  font-size: ${rhythm(1.25)};
  font-weight: 600;
  line-height: ${rhythm(1.25)};
  margin-bottom: ${rhythm(1.25)};
`

const subTitleCss = css`
  font-size: ${rhythm(0.75)};
  font-weight: 400; 
  line-height: ${rhythm(1.25)};
  margin-top: -${rhythm(1.25)};
  color: rgba(255, 255, 255, 0.9);
`
const cssLinkHero = css`
  text-decoration: none;
  color: white;
`
const logoCss = css`
  width: 60px;
  height: 60px;
  margin: 0;
  margin-bottom: -11px;
`


const AppHero = () => {
  const data = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    titleShort
                }
            }
        }
    `,
  )

  const inputSearchRef = useRef<HTMLInputElement>(null)
  const { setQuery } = useContext(SearchContext)

  return (
    <section css={heroCss}>
      <div css={heroBodyCss}>
        <Container>
          <h1 css={titleCss}>
            <Link css={cssLinkHero} to={`/`}>
                <span>
                  <img css={logoCss} alt={data.site.siteMetadata.titleShort} src={logo}/>
                </span>
              <span>{data.site.siteMetadata.titleShort}</span>
            </Link>
          </h1>
          <h2 css={subTitleCss}>
            Reiseplanung für Rad- und Wandertouren leicht gemacht.
          </h2>
          <div css={css`
             margin-bottom: -${rhythm(1)}
            `}>
            <div css={css`
                display: flex;
              `}>
              <input
                ref={inputSearchRef}
                css={css`
                    background: transparent;
                    border: none;
                    color: white;
                    outline: none;
                    border-bottom: solid 1px white;
                    width:100%;
                    transition: all 0.25s ease-in 0s;
                    &:focus,
                    &:not(:placeholder-shown) {
                      width: 100%;
                      margin-left: 0;
                    }
                    @media (min-width: 960px) {
                      margin-left: 75vw;
                      width: calc(100% - 75vw);
                    }
                  `}
                spellCheck="false"
                aria-label="Suche"
                autoComplete="off"
                id="search"
                placeholder=" "
                onChange={e => setQuery(e.currentTarget.value)}
                onKeyPress={(e) => {
                  // hit enter will blur - on mobile the keyboard hides
                  if (e.key === "Enter") {
                    inputSearchRef.current!.blur()
                  }
                }}
              />
              <i css={css`
                  cursor: pointer;
                `}
                 tabIndex={0}
                 className="icon search"
                 onClick={() => inputSearchRef.current!.focus()}
                 onKeyPress={() => inputSearchRef.current!.focus()}
                 aria-label="Suche"
                 role="button"/>
            </div>

          </div>
        </Container>
      </div>
    </section>
  )
}

export default React.memo(AppHero)
