import * as  avro from "avsc/etc/browser/avsc-types"

export const tripSearchType = avro.Type.forSchema(
  {
    "type": "record", "fields": [{
      "name": "trips", "type": {
        "type": "array", "items": {
          "type": "record",
          "fields": [{ "name": "id", "type": "int" }, { "name": "title", "type": "string" }, {
            "name": "p",
            "type": ["null", "string"],
          }, { "name": "lengthInKm", "type": "int" }, {
            "name": "level",
            "type": {
              "type": "array",
              "items": { "type": "record", "fields": [{ "name": "name", "type": "string" }] },
            },
          }, {
            "name": "fields",
            "type": {
              "type": "record",
              "fields": [{ "name": "yovelinoSlug", "type": "string" }, { "name": "mainPageExcerptHtml", "type": "string" }],
            },
          }, {
            "name": "mainPicture",
            "type": {
              "type": "record",
              "fields": [{
                "name": "localFile",
                "type": {
                  "type": "record",
                  "fields": [{
                    "name": "childImageSharp",
                    "type": {
                      "type": "record",
                      "fields": [{
                        "name": "fluid",
                        "type": {
                          "type": "record",
                          "fields": [{ "name": "base64", "type": "string" }, {
                            "name": "aspectRatio",
                            "type": "float",
                          }, { "name": "src", "type": "string" }, {
                            "name": "srcSet",
                            "type": "string",
                          }, { "name": "srcWebp", "type": "string" }, {
                            "name": "srcSetWebp",
                            "type": "string",
                          }, { "name": "sizes", "type": "string" }],
                        },
                      }],
                    },
                  }],
                },
              }],
            },
          }, { "name": "excerpt", "type": "string" }, { "name": "countries", "type": "string" }, {
            "name": "regions",
            "type": "string",
          }],
        },
      },
    }],
  },
)


