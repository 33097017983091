import * as React from "react"
import { CacheProvider } from "@emotion/react"
import { cacheOptions } from "../components/styled-components"
import createCache from "@emotion/cache"
import MainPage from "../components/mainPage"
import SEO from "../components/seo"
import AppFooter from "../components/appFooter"

export default () => {

  return (
    <CacheProvider value={createCache(cacheOptions)}>
      <SEO title={"Yovelino"}/>
      <MainPage/>
      <AppFooter/>
    </CacheProvider>
  )
}

