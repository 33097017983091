import { useContext, useEffect, useState } from "react"
import * as JsSearch from "js-search"
import { ColumnIsOneFifth, Columns } from "./styled-components"
import TourCard, { ITourCardFragment } from "./tour-card"
import { tripSearchType } from "../../avro/trip-search"
import * as  SnappyJS from "snappyjs"
import SearchContext from "../contexts/search-sontext"
import * as React from "react"

interface ISearch {
  search(query: string): ITourCardFragment[]
}


const Search = () => {

  const { query } = useContext(SearchContext)

  const [search, setSearch] = useState<ISearch | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {

    const loadTours = () => {
      if (isLoading) {
        return
      }

      const request = new XMLHttpRequest()

      request.open("GET", "tours.avro")
      request.responseType = "arraybuffer"

      request.addEventListener("load", () => {
        if (request.status >= 200 && request.status < 300) {
          // create a node buffer in the browser - otherwise the avro is not able to read the data
          const buf = new Buffer(SnappyJS.uncompress(request.response), "binary")
          const tourData = tripSearchType.fromBuffer(buf)
          console.log(tourData)
          rebuildIndex(tourData.trips)
        } else {
          console.warn(request.statusText, request.responseText)
          setIsLoading(false)
          console.log(request.statusText)
        }
      })
      request.send()
    }

    /**
     * rebuilds the overall index based on the options
     */
    const rebuildIndex = (tourList: ITourCardFragment[]) => {
      const search = new JsSearch.Search("id")
      search.indexStrategy = new JsSearch.PrefixIndexStrategy()
      search.sanitizer = new JsSearch.LowerCaseSanitizer()
      search.searchIndex = new JsSearch.TfIdfSearchIndex()

      search.addIndex("title")
      search.addIndex("excerpt")
      search.addIndex("countries")
      search.addIndex("regions")

      search.addDocuments(tourList)
      setSearch(search)
      setIsLoading(false)
    }

    if (query && !search && !isLoading) {
      setIsLoading(true)
      loadTours()
    }
  }, [query, search, isLoading])

  if (!query) {
    return (<div/>)
  }

  if (!search) {
    return (<div>Touren werden geladen...</div>)
  }

  const queryResult = search.search(query)
  if (queryResult.length === 0) {
    return (<div>Leider wurden keine Touren zu Ihrer Suche gefunden.</div>)
  }

  return (
    <Columns>
      {queryResult.map(node => (
        <ColumnIsOneFifth key={node.title}>
          <TourCard tour={node}/>
        </ColumnIsOneFifth>
      ))}
    </Columns>
  )

}

export default React.memo(Search)
