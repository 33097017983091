import { createContext } from "react"

interface ISearchContext {
  query: string;
  setQuery: (query: string) => void;
}

const SearchContext = createContext({} as ISearchContext)

export default SearchContext
