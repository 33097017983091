import AppHero from "./appHero"
import { ColumnIsOneFifth, Columns, ContainerOverview } from "./styled-components"
import Search from "./SearchContainer"
import TourCard, { ITourCardFragment } from "./tour-card"
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import SearchContext from "./../contexts/search-sontext"
import * as React from "react"

type Data = {
  site: {
    siteMetadata: {
      title: string;
      titleShort: string;
    };
  }
  trips: {
    edges: {
      node: ITourCardFragment
    }[]
  }
};

const MainPage = () => {
  const data: Data = useStaticQuery(graphql`
       query {
          site {
            siteMetadata {
              title,
              titleShort
            }
          }
          trips:allContentfulTrip(
              limit: 25, 
              sort:{fields:internal___contentDigest}
            ) {
            edges {
              node {
                ...TourCardFragment
              }
            }
          }
        }
    `,
  )


  const [query, setQuery] = useState("")

  return (
    <div>
      <SearchContext.Provider value={{ query, setQuery }}>
        <AppHero/>
        <ContainerOverview>
          <Search/>
          {!query && (
            <div>
              <h2>Empfohlene Touren</h2>
              <Columns>
                {data.trips.edges.map(edge => (
                  <ColumnIsOneFifth key={edge.node.title}>
                    <TourCard tour={edge.node}/>
                  </ColumnIsOneFifth>
                ))}
              </Columns>
            </div>
          )}

        </ContainerOverview>
      </SearchContext.Provider>
    </div>
  )
}

export default React.memo(MainPage)
